import React  from 'react';
import { Title, Container, Columns, Column } from 'react-bulma-companion/lib';

import Prize1st from '../../../assets/images/prizes-1st.svg';
import Prize2nd from '../../../assets/images/prizes-2nd.svg';
import Prize3rd from '../../../assets/images/prizes-3rd.svg';

export default function PrizesPage(){

  return (
    <div className="prizes-page is-fullscreen page">
      <Container>
        <Columns>
          <Column className="priced">
            <Title className="heading-1">1st prize</Title>
            <p className="detail">R100 000 towards your tuition fees or your pursuit
              <br/>
              of a creative design passion</p>
              <br/>
            <img src={Prize1st} alt=""/>
          </Column>
          <Column>
            <Title className="heading-1">2nd prize</Title>
            <p className="detail">A 13’’ MacBook Pro Plus a one-year
              <br/>
              Adobe CC licence</p>
            <br/>
            <img src={Prize2nd} alt=""/>
          </Column>
          <Column>
            <Title className="heading-1">3rd prize</Title>
            <p className="detail">A 13’’ MacBook Pro</p>
            <br/>
            <img src={Prize3rd} alt=""/>
          </Column>
        </Columns>
      </Container>
    </div>
  );

}
