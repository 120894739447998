import React, { useEffect, useState } from 'react';
import { Title, Container, Columns, Column } from 'react-bulma-companion';
import { useDispatch, useSelector } from 'react-redux';
import R from 'ramda';
import { push } from 'connected-react-router';

export default function StatusPage() {
  const { user } = useSelector(R.pick(['user']));
  const dispatch = useDispatch();
  const [auth, setAuth] = useState(!R.isEmpty(user));
  // useEffect(() => {
  //   setAuth(!R.isEmpty(user));
  // }, [user.username]);
  //
  // useEffect(() => {
  //   if (R.isEmpty(user)) {
  //     dispatch(push('/how-to-enter'));
  //   }
    setTimeout(() => { window.location = 'https://designacup.co.za/Design%20A%20Cup%20Brief.zip'; }, 2000);
  // }, [user]);

  return (
    <div className="status-page is-fullscreen">
      <Container>
        <div>
          <Title className="heading-1">
            thank you for downloading!
          </Title>
          <p className="title sub is-size-1">
            Your download will begin automatically
          </p>
        </div>
        <br />
        <br />
        <Columns centered>
          <Column className="is-narrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="208.5" height="117.548" viewBox="0 0 208.5 117.548">
              <g id="Group_2341" data-name="Group 2341" transform="translate(-643.133 -566.433)">
                <g id="Group_2252" data-name="Group 2252" transform="translate(643.133 566.433)">
                  <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="58.774" cy="58.774" rx="58.774" ry="58.774" transform="translate(0 0)" fill="#7fc241" />
                </g>
                <text id="Step_1" data-name="Step 1" transform="translate(693.36 630.846)" fill="#fff" fontSize="18" fontFamily="Ornito-DemiBold, Ornito" fontWeight="300"><tspan x="0" y="0">Step 1</tspan></text>
                <line id="Line_2" data-name="Line 2" x2="87.317" transform="translate(756.316 625.528)" fill="none" stroke="#7fc241" strokeLinecap="round" strokeWidth="16" />
                <g id="check_circle_black_24dp" transform="translate(655.454 607.206)">
                  <path id="Path_5978" data-name="Path 5978" d="M0,0H33.588V33.588H0Z" fill="none" />
                  <path id="Path_5979" data-name="Path 5979" d="M15.995,2A13.995,13.995,0,1,0,29.99,15.995,14,14,0,0,0,15.995,2Zm0,25.191a11.2,11.2,0,1,1,11.2-11.2A11.211,11.211,0,0,1,15.995,27.191ZM22.419,9.809,13.2,19.032,9.571,15.421,7.6,17.394l5.6,5.6,11.2-11.2Z" transform="translate(0.799 0.799)" fill="#fff" />
                </g>
              </g>
            </svg>
          </Column>
          <Column className="is-narrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="208.5" height="117.548" viewBox="0 0 208.5 117.548">
              <g id="Group_2341" data-name="Group 2341" transform="translate(-643.133 -566.433)">
                <g id="Group_2252" data-name="Group 2252" transform="translate(643.133 566.433)">
                  <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="58.774" cy="58.774" rx="58.774" ry="58.774" transform="translate(0 0)" fill="#7fc241" />
                </g>
                <text id="Step_1" data-name="Step 1" transform="translate(693.36 630.846)" fill="#fff" fontSize="18" fontFamily="Ornito-DemiBold, Ornito" fontWeight="300"><tspan x="0" y="0">Step 2</tspan></text>
                <line id="Line_2" data-name="Line 2" x2="87.317" transform="translate(756.316 625.528)" fill="none" stroke="#7fc241" strokeLinecap="round" strokeWidth="16" />
                <g id="check_circle_black_24dp" transform="translate(655.454 607.206)">
                  <path id="Path_5978" data-name="Path 5978" d="M0,0H33.588V33.588H0Z" fill="none" />
                  <path id="Path_5979" data-name="Path 5979" d="M15.995,2A13.995,13.995,0,1,0,29.99,15.995,14,14,0,0,0,15.995,2Zm0,25.191a11.2,11.2,0,1,1,11.2-11.2A11.211,11.211,0,0,1,15.995,27.191ZM22.419,9.809,13.2,19.032,9.571,15.421,7.6,17.394l5.6,5.6,11.2-11.2Z" transform="translate(0.799 0.799)" fill="#fff" />
                </g>
              </g>
            </svg>
          </Column>
          <Column className="has-text-left-desktop-only is-narrow">
            <Columns className="is-vcentered">
              <Column className="is-narrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="117.548" height="117.548" viewBox="0 0 117.548 117.548">
                  <g id="Group_2342" data-name="Group 2342" transform="translate(-871.816 -566.433)">
                    <g id="Group_2254" data-name="Group 2254" transform="translate(871.816 566.433)">
                      <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="58.774" cy="58.774" rx="58.774" ry="58.774" transform="translate(0 0)" fill="#F7B21F" />
                      <ellipse id="Ellipse_2" data-name="Ellipse 2" cx="44.08" cy="44.08" rx="44.08" ry="44.08" transform="translate(14.694 14.693)" fill="#efd8b8" />
                    </g>
                    <text id="Step_2" data-name="Step 2" transform="translate(902.161 630.846)" fill="#F7B21F" fontSize="18" fontFamily="Ornito-DemiBold, Ornito" fontWeight="300"><tspan x="0" y="0">Step 3</tspan></text>
                  </g>
                </svg>
              </Column>
              <Column>
                <p style={{ color: '#F7B21F' }}>Enter your design</p>
                <a href="/enter" style={{ color: 'white' }}>
                  SUBMIT YOUR ENTRY &nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <path id="Path_5939" data-name="Path 5939" d="M20,11H7.83l5.59-5.59L12,4,4,12l8,8,1.41-1.41L7.83,13H20Z" transform="translate(20 20) rotate(180)" fill="#eeaf00" />
                  </svg>
                </a>
              </Column>
            </Columns>

          </Column>
        </Columns>
      </Container>
    </div>
  );
}
