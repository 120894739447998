import React, { useState } from 'react';
import { Title, Container } from 'react-bulma-companion';

export default function BriefPage() {

  return (
    <div className="brief-page is-fullscreen">
      <Container>
        <Title className="heading-1">
          design brief
        </Title>
        <p>
          Are you ready to enter your cup design? <br /> Simply download our design brief and use our templates to get started.
        </p>
        <br/>
        <a href="/status" className="hero-cta alt" style={{width: '265px', height: '48px'}}>
          <span style={{paddingRight:'10px'}}>DOWNLOAD DESIGN BRIEF</span>

          <svg style={{ marginLeft: '5px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="arrow_back_black_24dp" fill="#fff" transform="translate(20 20) rotate(180)">
                <path id="Path_5939" data-name="Path 5939" d="M20,11H7.83l5.59-5.59L12,4,4,12l8,8,1.41-1.41L7.83,13H20Z" />
              </g>
            </svg>
        </a>
      </Container>
    </div>
  );
}
