import React  from 'react';
import { Title, Container, Columns, Column } from 'react-bulma-companion/lib';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

export default function HowToEnterPage() {
  const dispatch = useDispatch();

  return (
    <div className="how-to-enter-page is-fullscreen page">
      <Container>
        <Title className="heading-1">want to be a winner?</Title>
        <br />
        <Columns>
          <Column className="is-6">
            <Columns>
              <Column>

                <svg xmlns="http://www.w3.org/2000/svg" style={{height:'100px'}} viewBox="0 0 338.5 182.269">
                  <g id="Group_2279" data-name="Group 2279" transform="translate(-278 -441.866)">
                    <g id="Group_2252" data-name="Group 2252" transform="translate(278 405.393)">
                      <circle id="Ellipse_1" data-name="Ellipse 1" cx="91.134" cy="91.134" r="91.134" transform="translate(0 36.472)" fill="#ebd9bc" />
                      <circle id="Ellipse_2" data-name="Ellipse 2" cx="68.351" cy="68.351" r="68.351" transform="translate(22.784 59.256)" fill="#a51831" />
                    </g>
                    <text id="Step_1" data-name="Step 1" transform="translate(325 544)" fill="#ebd9bc" fontSize="29" fontFamily="Ornito-DemiBold, Ornito" fontWeight="600"><tspan x="0" y="0">Step 1</tspan></text>
                  </g>
                  <line id="Line_2" className="hideline" data-name="Line 2" x2="155" transform="translate(175.5 91.634)" fill="none" stroke="#ebd9bc" strokeLinecap="round" strokeWidth="16" />
                </svg>
                <div className="howtodiv">
                  <Title className="is-2" style={{ color: '#EBD9BC' }}>Register</Title>
                  <a onClick={() => dispatch(push('/register'))} style={{ width: '140px' }}>
                  <span className="downloadbrief">
                  REGISTER
                  </span>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                      <path id="Path_5939" data-name="Path 5939" d="M20,11H7.83l5.59-5.59L12,4,4,12l8,8,1.41-1.41L7.83,13H20Z" transform="translate(20 20) rotate(180)" fill="#eeaf00" />
                    </svg>

                  </a>
                </div>
              </Column>
              <Column>
                <svg xmlns="http://www.w3.org/2000/svg"  style={{height:'100px'}} viewBox="0 0 338.5 182.269">
                  <g id="Group_2254" data-name="Group 2254" transform="translate(0 -36.472)">
                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="91.134" cy="91.134" r="91.134" transform="translate(0 36.472)" fill="#e75411" />
                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="68.351" cy="68.351" r="68.351" transform="translate(22.784 59.256)" fill="#a51831" />
                  </g>
                  <text id="Step_2" data-name="Step 2" transform="translate(47 102.134)" fill="#dc5615" fontSize="29" fontFamily="Ornito-DemiBold, Ornito" fontWeight="600"><tspan x="0" y="0">Step 2</tspan></text>
                  <line id="Line_7" className="hideline" data-name="Line 7" x2="155" transform="translate(175.5 91.634)" fill="none" stroke="#e75411" strokeLinecap="round" strokeWidth="16" />
                </svg>
                <div className="howtodiv">
                  <Title className="is-2" style={{ color: '#E75411', fontWeight: 600 }}>
                    Use the design
                    templates and bring
                    that celebration to life.
                    You’ve got 3-weeks.
                  </Title>
                  <a href="/status">
                    <span className="downloadbrief">
                    DOWNLOAD DESIGN BRIEF
                    </span>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                      <path id="Path_5939" data-name="Path 5939" d="M20,11H7.83l5.59-5.59L12,4,4,12l8,8,1.41-1.41L7.83,13H20Z" transform="translate(20 20) rotate(180)" fill="#eeaf00" />
                    </svg>

                  </a>
                </div>
              </Column>
            </Columns>


          </Column>
          <Column className="is-6">
            <Columns>
              <Column>

              <svg xmlns="http://www.w3.org/2000/svg" style={{ height:'100px'}} viewBox="0 0 338.5 182.269">
              <g id="Group_2255" data-name="Group 2255" transform="translate(0 -36.472)">
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="91.134" cy="91.134" r="91.134" transform="translate(0 36.472)" fill="#f7b21f" />
                <circle id="Ellipse_2" data-name="Ellipse 2" cx="68.351" cy="68.351" r="68.351" transform="translate(22.784 59.256)" fill="#a51831" />
              </g>
              <text id="Step_3" data-name="Step 3" transform="translate(47 102.134)" fill="#f7b21f" fontSize="29" fontFamily="Ornito-DemiBold, Ornito" fontWeight="600"><tspan x="0" y="0">Step 3</tspan></text>
              {/* <line id="Line_2" className="hideline" data-name="Line 2" x2="155" transform="translate(175.5 91.634)" fill="none" stroke="#f7b21f" strokeLinecap="round" strokeWidth="16" />
               */}
            </svg>
                <div className="howtodiv">
                <p className="is-size-5">
                    Along with a motivational write-up, telling
                    us what it would mean to you to win that
                    R100 000 towards your study fees.
                    Along with your design and motivational
                    write-up, please submit the following:
                  </p>
                  <br/>
                  <ul className="is-size-5">
                    <li>- Full name</li>
                    <li>- ID number</li>
                    <li>- Phone number</li>
                    <li>- Email address</li>
                    <li>- Institution you enrolled at and what year you are in</li>
                    <li>- Design rationale</li>
                  </ul>
                  <a href="" onClick={() => dispatch(push('/enter'))}>
                  <span className="downloadbrief">
                  Submit Your Entry
                  </span>
                
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <path id="Path_5939" data-name="Path 5939" d="M20,11H7.83l5.59-5.59L12,4,4,12l8,8,1.41-1.41L7.83,13H20Z" transform="translate(20 20) rotate(180)" fill="#eeaf00" />
                </svg>

              </a>
                </div>
              </Column>
              <Column>
              {/* <svg xmlns="http://www.w3.org/2000/svg" style={{ height:'100px'}} viewBox="0 0 338.5 182.269">
              <g id="Group_2255" data-name="Group 2255" transform="translate(0 -36.472)">
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="91.134" cy="91.134" r="91.134" transform="translate(0 36.472)" fill="#ebd9bc" />
                <circle id="Ellipse_2" data-name="Ellipse 2" cx="68.351" cy="68.351" r="68.351" transform="translate(22.784 59.256)" fill="#a51831" />
              </g>
              <text id="Step_3" data-name="Step 3" transform="translate(47 102.134)" fill="#ebd9bc" fontSize="29" fontFamily="Ornito-DemiBold, Ornito" fontWeight="600"><tspan x="0" y="0">Step 4</tspan></text>
              
            </svg> */}

          <div  style={{height:108}}>

          </div>
                <div className="howtodiv">
                <p className="is-size-5">
                    Please remember that the use of existing designs, concepts or other copyrighted, registered or trademarked material relating to other brands, icons, public figures and personalities is strictly prohibited.
                    <br/><br/>
                    Failure to do so will result in immediate disqualification of the creative submission.
                  </p>
               
                </div>
              </Column>
            </Columns>


          </Column>        
        </Columns>
      </Container>
    </div>
  );
}