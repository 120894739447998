import React, { useEffect, useState } from 'react';
import { Title, Container, Columns, Column } from 'react-bulma-companion/lib';
import Field from 'react-bulma-companion/lib/Field';
import Button from 'react-bulma-companion/lib/Button';
import Control from 'react-bulma-companion/lib/Control';
import Input from 'react-bulma-companion/lib/Input';
import File from 'react-bulma-companion/lib/File';
import TextArea from 'react-bulma-companion/lib/Textarea';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import R from 'ramda';
import Icon from 'react-bulma-companion/lib/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import Help from 'react-bulma-companion/lib/Help';
import { postCheckUsername } from '../../../api/users';
import { validateUsername, validatePassword } from '../../../utils/validation';
import UploadedIcon from '../../../assets/flaticons/uploaded.svg';
import UploadIcon from '../../../assets/flaticons/upload.svg';
import { attemptRegister } from '../../../store/thunks/auth';

export default function EnterPage() {
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));
  const [usernameMessage, setUsernameMessage] = useState('');
  const [password, setPassword] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [usernameAvailable, setUsernameAvailable] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [name, setName] = useState(user.firstName ? user.firstName : '');
  const [surname, setSurname] = useState(user.lastName ? user.lastName : '');
  const [username, setUsername] = useState(user.username ? user.username : '');
  const [contact, setContact] = useState(user.contact ? user.contact : '');
  const [idnumber, setIdnumber] = useState(user.ID ? user.ID : '');
  const [institution, setInstitution] = useState();

  const register = (e) => {
    e.preventDefault();
    // create formdata object with all the form inputs by name and post to register api
    const data = new FormData(e.target);
    dispatch(attemptRegister(data, true))
      .catch(R.identity);
  };
  const handleUsernameChange = e => {
    setUsername(e.target.value);
    // checkUsername(e.target.value);
  };
  const handleNameChange = e => {
    setName(e.target.value);
  };
  const handleSurnameChange = e => {
    setSurname(e.target.value);
  };
  const handleIdchange = e => {
    setIdnumber(e.target.value);
  };
  const handleContactChange = e => {
    setContact(e.target.value);
  };
  const handleinstitutionChange = e => {
    setInstitution(e.target.value);
  };
  const handlePasswordChange = e => {
    setPassword(e.target.value);
    // checkPassword(username, e.target.value);
  };

  const DesignUpload = () => (
    <div>
      <Title className="design-title is-size-3">
        Design One
      </Title>
      <div className="file-input-wrapper">

        <div className="file is-normal">
          <label className="file-label">
            <input
              className="file-input"
              type="file"
              name="resumeUpload16oz500one"
              onChange={(e) => {
                e.target.parentElement.getElementsByClassName('file-cta')[0].classList.add('active');
                e.target.parentElement.getElementsByClassName('file-label')[0].textContent = e.target.files[0].name;
              }}
            />
            <span className="file-cta">

              <span className="file-label">
                Upload your 16oz/500 ml cup design
              </span>
              <span className="file-icon">
                <img src={UploadedIcon} className="uploaded-icon" alt="" />
                <img src={UploadIcon} className="upload-icon" alt="" />

              </span>
            </span>
          </label>
        </div>

        <div className="file is-normal">
          <label className="file-label">
            <input
              className="file-input"
              type="file"
              name="resumeUpload12oz500one"
              onChange={(e) => {
                e.target.parentElement.getElementsByClassName('file-cta')[0].classList.add('active');
                e.target.parentElement.getElementsByClassName('file-label')[0].textContent = e.target.files[0].name;
              }}
            />
            <span className="file-cta">

              <span className="file-label">
                Upload your 12oz/350ml cup design
              </span>
              <span className="file-icon">
                <img src={UploadedIcon} className="uploaded-icon" alt="" />
                <img src={UploadIcon} className="upload-icon" alt="" />

              </span>
            </span>
          </label>
        </div>

        <div className="file is-normal">
          <label className="file-label">
            <input
              className="file-input"
              type="file"
              name="motivation_letter_upload_one"
              onChange={(e) => {
                e.target.parentElement.getElementsByClassName('file-cta')[0].classList.add('active');
                e.target.parentElement.getElementsByClassName('file-label')[0].textContent = e.target.files[0].name;
              }}
            />
            <span className="file-cta">

              <span className="file-label">
                Upload your motivational letter
              </span>
              <span className="file-icon">
                <img src={UploadedIcon} className="uploaded-icon" alt="" />
                <img src={UploadIcon} className="upload-icon" alt="" />

              </span>
            </span>
          </label>
        </div>

        <div className="file is-normal">
          <label className="file-label">
            <input
              className="file-input"
              type="file"
              name="copyright_references_upload_one"
              onChange={(e) => {
                e.target.parentElement.getElementsByClassName('file-cta')[0].classList.add('active');
                e.target.parentElement.getElementsByClassName('file-label')[0].textContent = e.target.files[0].name;
              }}
            />
            <span className="file-cta">

              <span className="file-label">
                Copyright references
              </span>
              <span className="file-icon">
                <img src={UploadedIcon} className="uploaded-icon" alt="" />
                <img src={UploadIcon} className="upload-icon" alt="" />

              </span>
            </span>
          </label>
        </div>

      </div>
      <TextArea
        name="rationale_input_one"
        placeholder="Design rationale"
      />
    </div>
  );
  const DesignUploadTwo = () => (
    <div>
      <Title className="design-title is-size-3">
        Design Two
      </Title>
      <div className="file-input-wrapper">

        <div className="file is-normal">
          <label className="file-label">
            <input
              className="file-input"
              type="file"
              name="resumeUpload16oz500two"
              onChange={(e) => {
                e.target.parentElement.getElementsByClassName('file-cta')[0].classList.add('active');
                e.target.parentElement.getElementsByClassName('file-label')[0].textContent = e.target.files[0].name;
              }}
            />
            <span className="file-cta">

              <span className="file-label">
                Upload your 16oz/500 ml cup design
              </span>
              <span className="file-icon">
                <img src={UploadedIcon} className="uploaded-icon" alt="" />
                <img src={UploadIcon} className="upload-icon" alt="" />

              </span>
            </span>
          </label>
        </div>

        <div className="file is-normal">
          <label className="file-label">
            <input
              className="file-input"
              type="file"
              name="resumeUpload12oz500two"
              onChange={(e) => {
                e.target.parentElement.getElementsByClassName('file-cta')[0].classList.add('active');
                e.target.parentElement.getElementsByClassName('file-label')[0].textContent = e.target.files[0].name;
              }}
            />
            <span className="file-cta">

              <span className="file-label">
                Upload your 12oz/350ml cup design
              </span>
              <span className="file-icon">
                <img src={UploadedIcon} className="uploaded-icon" alt="" />
                <img src={UploadIcon} className="upload-icon" alt="" />

              </span>
            </span>
          </label>
        </div>

        <div className="file is-normal">
          <label className="file-label">
            <input
              className="file-input"
              type="file"
              name="motivation_letter_upload_two"
              onChange={(e) => {
                e.target.parentElement.getElementsByClassName('file-cta')[0].classList.add('active');
                e.target.parentElement.getElementsByClassName('file-label')[0].textContent = e.target.files[0].name;
              }}
            />
            <span className="file-cta">

              <span className="file-label">
                Upload your motivational letter
              </span>
              <span className="file-icon">
                <img src={UploadedIcon} className="uploaded-icon" alt="" />
                <img src={UploadIcon} className="upload-icon" alt="" />

              </span>
            </span>
          </label>
        </div>

        <div className="file is-normal">
          <label className="file-label">
            <input
              className="file-input"
              type="file"
              name="copyright_references_upload_two"
              onChange={(e) => {
                e.target.parentElement.getElementsByClassName('file-cta')[0].classList.add('active');
                e.target.parentElement.getElementsByClassName('file-label')[0].textContent = e.target.files[0].name;
              }}
            />
            <span className="file-cta">

              <span className="file-label">
                Copyright references
              </span>
              <span className="file-icon">
                <img src={UploadedIcon} className="uploaded-icon" alt="" />
                <img src={UploadIcon} className="upload-icon" alt="" />

              </span>
            </span>
          </label>
        </div>

      </div>
      <TextArea
        name="rationale_input_two"
        placeholder="Design rationale"
      />
    </div>
  );

  return (
    <div className="enter-page is-fullscreen page">
      {success
        ? (
          <div className="has-text-centered py-5">
            <Title className="heading-1">thank you for your design!</Title>
            <Title className="sub is-size-3">
              Winners will be contacted once all entries
              <br />
              have been judged, good luck!
            </Title>
          </div>
        )
        : (
          <Container>
            <Title className="heading-1">
              look’s like you’re
              <br />
              ready to enter!
            </Title>
            <Title className="sub is-size-3">
              Let’s get your design entered, simply fill in the below,
              <br />
              upload your design and submit
            </Title>
            <form onSubmit={register} encType="multipart/form-data">
              <Columns>
                <Column>
                  <Field>
                    <Control>
                      <Input
                        type="text"
                        placeholder="Your Name"
                        name="first_name"
                        required
                        value={name}
                        onChange={handleNameChange}
                      />
                    </Control>
                  </Field>
                </Column>
                <Column>
                  <Field>
                    <Control>
                      <Input
                        type="text"
                        placeholder="Your Surname"
                        name="last_name"
                        required
                        value={surname}
                        onChange={handleSurnameChange}
                      />
                    </Control>
                  </Field>
                </Column>
              </Columns>
              <Columns>
                <Column>
                  <Field>
                    <Control>
                      <Input
                        id="Email"
                        placeholder="Your email address"
                        name="username"
                        required
                        type="text"
                        value={username}
                        onChange={handleUsernameChange}
                      />
                    </Control>
                  </Field>
                </Column>
                <Column>
                  <Field>
                    <Control>
                      <Input
                        type="text"
                        placeholder="Your contact number"
                        name="contact"
                        id="contact"
                        onChange={handleContactChange}
                      />
                    </Control>
                  </Field>
                </Column>
              </Columns>
              <Columns>
                <Column>
                  <Field>
                    <Control>
                      <Input
                        type="text"
                        placeholder="ID number"
                        name="ID"
                        id="ID"
                        value={idnumber}
                        onChange={handleIdchange}
                      />
                    </Control>
                  </Field>
                </Column>
              </Columns>
              <Columns>
                <Column>
                  <Field>
                    <Control>
                      <Control>
                        <Input
                          id="password"
                          name="password"
                          placeholder="Password"
                          type="password"
                          value={password}
                          onChange={handlePasswordChange}
                        />

                      </Control>
                    </Control>
                  </Field>
                </Column>
              </Columns>
              <Columns>
                <Column>
                  <Columns style={{ justifyContent: 'space-between' }}>
                    <Column style={{ justifyContent: 'center' }} className="is-7 spaced-div">
                      <p>Are you currently a student?</p>
                    </Column>
                    <Column>
                      <div className="select is-rounded is-fullwidth">
                        <select>
                          <option>Select</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                    </Column>
                  </Columns>
                </Column>
                <Column>
                  <Field>
                    <Control>
                      <Input
                        type="text"
                        placeholder="If yes, which institution?"
                        name="institution"
                        id="institution"
                        onChange={handleinstitutionChange}
                      />
                    </Control>
                  </Field>
                </Column>
              </Columns>
              <br />
              <DesignUpload />
              <br />
              <DesignUploadTwo />
              <br />
              <br />
              <Columns>
                <Column className="spaced-div is-5">
                  <label className="checkbox">
                    <input type="checkbox" />
                    I would like to receive all competition updates
                  </label>
                </Column>
                <Column className="spaced-div">
                  <p>
                    By submitting you accept our <a href="/tcs" onClick={() => dispatch(push('/tcs'))}>terms & conditions</a>
                  </p>
                  <Button color="primary" type="submit" size="medium" rounded>SUBMIT</Button>
                </Column>
              </Columns>
            </form>
          </Container>
        )}
    </div>
  );
}
