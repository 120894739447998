import React  from 'react';
import { Title, Container, Columns, Column } from 'react-bulma-companion/lib';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

export default function RulesPage(){
 const dispatch = useDispatch();
  return (
    <div className="rules-page is-fullscreen page">
      <Container>
        <Title className="heading-1">
          want to be
          a winner?
        </Title>
        <Title className="sub is-2">
          Follow these steps
        </Title>
        <Title>
          Step 1
        </Title>
        <div>
          <p>Visualise your celebration.</p>
        </div>
        <Title>
          Step 2
        </Title>
        <div>
          <p>Use the design templates provided and bring that celebration to life.</p>
        </div>
        <Title>
          Step 3
        </Title>
        <div>
          <p>Submit your design on www.designacup. co.za, with a motivational write-up, telling us what it would mean to you to win that R100 000 towards your study fees. Along with your design and motivational write-up, please submit the following:</p>
          <ul>
            <li>- Full name</li>
            <li>- ID number</li>
            <li>- Phone number</li>
            <li>- Email address</li>
            <li>- Institution you enrolled at and what year you are in</li>
            <li>- Design rationale</li>
          </ul>
          <br/>
          <p>Please remember that the use of existing designs, concepts or other copyrighted, registered or trademarked material relating to other brands, icons, public figures and personalities is strictly prohibited. Failure to do so will result in immediate
            disqualification of the creative submission.</p>
          <br/>
          <strong onClick={() => dispatch(push('/tcs'))} style={{ color: '#A3132D', cursor: 'pointer', margin: '20px 0',
            display: 'flex'}}>VIEW Ts&Cs&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path id="Path_5939" data-name="Path 5939" d="M20,11H7.83l5.59-5.59L12,4,4,12l8,8,1.41-1.41L7.83,13H20Z" transform="translate(20 20) rotate(180)" fill="#a51831"/>
          </svg>
          </strong>
        </div>

      </Container>
    </div>
  );

}
