import React, { useState } from 'react';
import { Title, Container, Columns, Column } from 'react-bulma-companion/lib';
import Showcase2020Sub1 from '../../../assets/images/showcase-2020-1.svg';
import Showcase2020Sub2 from '../../../assets/images/showcase-2020-2.svg';
import Showcase2020Sub3 from '../../../assets/images/showcase-2020-3.svg';
import Showcase2019Sub1 from '../../../assets/images/showcase-2019-1.svg';
import Showcase2019Sub2 from '../../../assets/images/showcase-2019-2.svg';
import Showcase2019Sub3 from '../../../assets/images/showcase-2019-3.svg';
import Image2021 from '../../../assets/images/showcase-bg.svg';
import ShowcaseIcon from '../../../assets/images/view-showcase-icon.svg';

export default function ShowcasePage() {
  const [activeYear, setActiveYear] = useState(2021);
  const [activePreview, setActivePreview] = useState(0);
  const [activePreviewInfo, setActivePreviewInfo] = useState({
    name: '',
    description: '',
    object: '',
  });
  if (activePreview) {
    document.body.classList.add('hide_footer');
  } else {
    document.body.classList.remove('hide_footer');
  }
  const Winners2019 = () => (
    <div className="showcase-columns">
      <Columns>
        <Column>
          <div
            className="showcase-box"
            onClick={() => {
              setActivePreview(1);
              setActivePreviewInfo({ name: 'Tannah', description: '...', object: Showcase2019Sub1 });
            }}
          >
            <img src={Showcase2019Sub1} alt="Kabelo Diphokos submission" />
            <img src={ShowcaseIcon} className="showcase-icon" alt="" />
            <p className="topthree">Tannah Renou</p>
            <p>Vega</p>
          </div>
          <Title className="ranking">
            <strong>1</strong>
            <sup>st</sup>
          </Title>
        </Column>
        <Column>
          <div
            className="showcase-box"
            onClick={() => {
              setActivePreview(2);
              setActivePreviewInfo({ name: 'Barry', description: '...', object: Showcase2019Sub2 });
            }}
          >
            <img src={Showcase2019Sub2} alt="Kabelo Diphokos submission" />
            <img src={ShowcaseIcon} className="showcase-icon" alt="" />

            <p className="topthree">Barry Beukes</p>
            <p>Vega</p>
          </div>
          <Title className="ranking">
            <strong>2</strong>
            <sup>nd</sup>
          </Title>
        </Column>
        <Column>
          <div
            className="showcase-box"
            onClick={() => {
              setActivePreview(3);
              setActivePreviewInfo({ name: 'Barry', description: '...', object: Showcase2019Sub3 });
            }}
          >
            <img src={Showcase2019Sub3} alt="Kabelo Diphokos submission" />
            <img src={ShowcaseIcon} className="showcase-icon" alt="" />

            <p className="topthree">Tannah Renou</p>
            <p>Vega</p>
          </div>
          <Title className="ranking">
            <strong>3</strong>
            <sup>rd</sup>
          </Title>
        </Column>
      </Columns>
    </div>
  );
  const Winners2020 = () => (
    <div className="showcase-columns">
      <Columns>

        <Column>
          <div
            className="showcase-box"
            onClick={() => {
              setActivePreview(2);
              setActivePreviewInfo({ name: 'Kabelo Diphoko', description: 'North West University', object: Showcase2020Sub1 });
            }}
          >
            <img src={Showcase2020Sub1} alt="Kabelo Diphokos submission" />
            <img src={ShowcaseIcon} className="showcase-icon" alt="" />
            <Title>Kabelo Diphoko</Title>
            <p>North West University</p>
          </div>
          <Title className="ranking">
            <strong>1</strong>
            <sup>st</sup>
          </Title>
        </Column>
        <Column>
          <div
            className="showcase-box"
            onClick={() => {
              setActivePreview(2);
              setActivePreviewInfo({ name: 'Galya Raff', description: 'University of Johannesburg', object: Showcase2020Sub2 });
            }}
          >
            <img src={Showcase2020Sub2} alt="Kabelo Diphokos submission" />
            <img src={ShowcaseIcon} className="showcase-icon" alt="" />
            <Title>Galya Raff</Title>
            <p>University of Johannesburg</p>
          </div>
          <Title className="ranking">
            <strong>2</strong>
            <sup>nd</sup>
          </Title>
        </Column>
        <Column>
          <div
            className="showcase-box"
            onClick={() => {
              setActivePreview(2);
              setActivePreviewInfo({ name: 'Ashton Heldsinger', description: 'Cape Peninsula University of Technology', object: Showcase2020Sub3 });
            }}
          >
            <img src={Showcase2020Sub3} alt="Kabelo Diphokos submission" />
            <img src={ShowcaseIcon} className="showcase-icon" alt="" />
            <Title>Ashton Heldsinger</Title>
            <p>Cape Peninsula University of Technology</p>
          </div>
          <Title className="ranking">
            <strong>3</strong>
            <sup>rd</sup>
          </Title>
        </Column>

      </Columns>
      <video width="1184" height="659"   className="videoTop" controls poster="https://designacup.co.za/images/home-hero.png">
      <source src="https://designacup.co.za/DAC-Winner-Video-Music.mp4" type="video/mp4"/>
     </video>
    </div>
  );
  return (
    <div className="showcase-page page is-fullscreen">

      <Container className="year-selector-bar" fluid>
        <div className={activeYear === 2019 && 'active'} onClick={() => { setActiveYear(2019); }}>2019</div>
        <p>|</p>
        <div className={activeYear === 2020 && 'active'} onClick={() => { setActiveYear(2020); }}>2020</div>
        <p>|</p>
        <div className={activeYear === 2021 && 'active'} onClick={() => { setActiveYear(2021); }}>2021</div>
      </Container>
      {activePreview
        ? (
          <div className="preview">
            <div>
              <svg onClick={() => setActivePreview(0)} xmlns="http://www.w3.org/2000/svg" width="25.667" height="25.667" viewBox="0 0 25.667 25.667" style={{ position: 'absolute', right: 0, marginRight: '15px' }}><path id="Path_5985" data-name="Path 5985" d="M30.667,7.585,28.082,5,17.833,15.248,7.585,5,5,7.585,15.248,17.833,5,28.082l2.585,2.585L17.833,20.418,28.082,30.667l2.585-2.585L20.418,17.833Z" transform="translate(-5 -5)" fill="#a51831" /></svg>
              <Title>{activePreviewInfo.name}</Title>
              <p>{activePreviewInfo.description}</p>
            </div>
            <img src={activePreviewInfo.object} alt="" />
          </div>
        )
        : (
          <Container className="awards2021">
           

            <Title className="sub is-size-1 activeyear2021">
              {activeYear === 2021
                ? <>Have you got the design passion to get you to the 2021 top 3?</>
                :
                  <>
                    {activeYear} top three
                  </>
              }

            </Title>
            {activeYear === 2019 && (
              <Winners2019 />
            )}
            {activeYear === 2020 && (

              <Winners2020 />
            )}
            {activeYear === 2021 && (
              <>
              <Title className="heading-1">
                your creative flex, could make you next!
              </Title>
                <img style={{width: '850px'}} className="a2021" src={Image2021} alt="" />
              </>
            )}
           
          </Container>
        )}
    </div>
  );
}
