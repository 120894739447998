import React  from 'react';
import { Title, Container, Columns, Column } from 'react-bulma-companion/lib';

export default function TcsPage(){

  return (
    <div className="rules-page page">
      <Container>
        <Title className="heading-1">
          terms &
          <br/>
          conditions
        </Title>
        <div className="p-2">
          <p>Design A Cup Competition &ndash; Terms and Conditions</p>
          <p>BPSA - Wild Bean Caf&eacute;, My Cup My Expression (&ldquo;the Competition&rdquo;)</p>
          <p><br/></p>
          <p>The terms and conditions set out below (&ldquo;Terms and Conditions&rdquo;) apply to the Competition being run and administered by BP Southern Africa (Pty) Ltd (the &ldquo;Promoter&rdquo; or &ldquo;us&rdquo; or &ldquo;we&rdquo;). These Terms and Conditions will prevail in the event of any conflict or inconsistency with any other communication we send you in respect of the Competition, including advertising or promotional materials. Prize redemption instructions are deemed to form part of the Terms and Conditions and by entering this Competition you are deemed to be a participant (hereinafter referred to as &ldquo;you&rdquo; or &ldquo;Participant&rdquo;) and all Participants will be deemed to have accepted and shall be bound by, these Terms and Conditions. This version of the Terms and Conditions</p>
          <p>applies to Participants in the Republic of South Africa.</p>
          <p><br/></p>
          <p>1. Important Provisions</p>
          <p><br/></p>
          <p>1.1. We have a duty, in terms of the Consumer Protection Act, No. 68 of 2008 to point out certain important provisions in these Terms and Conditions to</p>
          <p>you. The clauses which contain these important provisions and the reasons why they are important are set out below. It is very important that you read all of these Terms and Conditions carefully and not only what we point out below.</p>
          <p><br/></p>
          <p>1.2. Limitation of risk, legal responsibilities and liability: Clauses included in clauses 2.10 to 2.16 below are important because they limit and exclude</p>
          <p>obligations, liabilities and legal responsibilities that we and other persons or entities may otherwise have to you. As a result of these clauses, your</p>
          <p>rights and remedies against us and these other persons and entities are limited or excluded. These clauses also limit or exclude your right to</p>
          <p>recover from, or make claims against, the Promoter for losses, damages, liability or harm that you or others may suffer as a result of your</p>
          <p>participation in the Competition. Clauses 2.12 and 2.16 below are particularly important because you take on risk, legal responsibilities and liability. In terms of these clauses, you may be responsible for taxes, fees, claims and other amounts. You will also be responsible for, and you accept, various risks, damages, harm, and injury which may be suffered by you and others for what is stated in these clauses.</p>
          <p><br/></p>
          <p>1.3. Indemnities given by you: Clause 2.12 below requires you to indemnify (hold harmless) the Promoter and other persons or entities referred to</p>
          <p>therein against claims, loss, damages, and harm that may be suffered as a result of your participation in any way in this campaign. This places various</p>
          <p>risks, liabilities, obligations and legal responsibilities on you, and you will be responsible and liable for the payment of the value of the claims, loss, damages, and harm that may be suffered or claimed.</p>
          <p><br/></p>
          <p>1.4. Consent given by you: Clause 1.10 states that by participating in the Competition you are giving your consent to your name being published in</p>
          <p>the traditional media and on social and digital media if you are a winner. Clause 1.11 states that you are also giving your consent to your image being used in the Promoter&rsquo;s marketing material, and to your participation in any marketing activity of the Promoter should you be a Winner. You</p>
          <p>have the right to withdraw your consent to have your image used in any marketing material or to participate in any marketing activity on written</p>
          <p>notice to the Promoter.</p>
          <p><br/></p>
          <p>1.5. The Promoter is BP Southern Africa (Pty) Ltd (Registration Number: Reg 1924/002602/07) of 199 Oxford Road, Oxford Parks, Dunkeld, 2196.</p>
          <p><br/></p>
          <p>1.6. The Competition is only valid in South Africa and is open only to a natural person, who:</p>
          <p><br/></p>
          <p>i) is legally resident in South Africa as either (a) a South African citizen; (b) a South African permanent resident; or (c) a student or professional in possession of a valid South African permit;</p>
          <p>ii) is at least eighteen years (18) old but younger than twenty-six (26) years old;</p>
          <p>iii) is currently either enrolled in a formal tertiary institution or is looking to be enrolled in a formal institution to further their creative designstudies; and</p>
          <p>iv) has not been one of the winners of any preceding Wild Bean Caf&eacute; design a cup competition.</p>
          <p><br/></p>
          <p>1.7. The Competition is not open to the Promoter or to BP plc or any of its subsidiaries (&ldquo;the BP Group&rdquo;), including their agencies, sales agents/</p>
          <p>merchandisers, packaging suppliers and their immediate family members (spouses, life partners, parents, grandparents, siblings, children and grandchildren) or to their business partners or any person who is (i) a director, member, partner, or agent of, or consultant to the Promoter and/or to the BP Group or any other person who directly or indirectly controls or is controlled by, the Promoter and/or the BP Group; or (ii) a supplier of goods or services in connection with the Promoter and/or the BP Group.</p>
          <p><br/></p>
          <p>1.8. The Competition is not open to employees and their immediate families of the Promoter and/or the BP Group.</p>
          <p><br/></p>
          <p>1.9. Participation in this Competition constitutes your acceptance of these Terms and Conditions. All information relating to this Competition and published on any promotional material will form part of these terms and conditions of entry. The Promoter reserves the right to amend these Terms</p>
          <p>and Conditions in its sole discretion at any time and the amendments will be deemed to have taken effect on the date of publication of the revised</p>
          <p>Terms and Conditions on the Promoter&rsquo;s website.</p>
          <p><br/></p>
          <p>1.10. By participating in the Competition, you are consenting to your name being published (at no fee) on any traditional or digital communication</p>
          <p>channels should you be a winner.</p>
          <p><br/></p>
          <p>1.11. By participating in the competition, you are consenting to your image being used (at no fee) in the Promoter&rsquo;s marketing material should you be</p>
          <p>a winner, and you are consenting to your participation in any marketing activity of the Promoter, including but not limited to interviews and</p>
          <p>collaborations, should you be a Winner. You have the right to withdraw your consent to have your image used in any marketing material or to</p>
          <p>participate in any marketing activity on written notice to the Promoter.</p>
          <p><br/></p>
          <p>1.12. The Competition will run from 17 June 2021 at 00h00 and end on 4 July 2021 at 23h59 (“the Competition Period”). No entries received before 17 June 2021 00h00 and after 23h59 on 4 July 2021 will be accepted. The Promoter reserves the right to extend the Competition Period by notice published on its website.
          </p>
          <p><br/></p>
          <p>2. Competition Entry Mechanic and specific conditions of entry Participants wishing to participate in the Competition must, during the Competition</p>
          <p>Period comply with the following entry mechanic:</p>
          <p><br/></p>
          <p>2.1. Their design must be submitted to the Promoter, inspired by the theme for the year, which incorporates the Wild Bean Caf&eacute; Ama20 Birthday</p>
          <p>Celebration and My cup My expression.</p>
          <p><br/></p>
          <p>2.2. The Participant must submit his/her entry online on www.designacup.co.za. The entry must be received on or before 23h59 on 4 July 2021 (or by such later date as the Promoter may formally permit by written notice published on its website).</p>
          <p><br/></p>
          <p>2.3. No group entries will be allowed and all designs submitted must be the original work of the Participant, which the Participant warrants is the case.</p>
          <p><br/></p>
          <p>2.4. The Promoter shall own the rights to all designs submitted by Participants and shall be entitled to alter such designs as the Promoter deems appropriate.</p>
          <p><br/></p>
          <p>2.5. By participating in the Competition, the Participant hereby cedes and assigns all the rights in and to the designs to the Promoter, with the Participant having no claim for any remuneration or compensation in this regard. The Participant further agrees to provide the Promoter, upon request, with all the files related to the submitted designs.</p>
          <p><br/></p>
          <p>2.6. To the extent that a design is used by the Promoter on any promotional material, the designer shall be credited in such promotional material.</p>
          <p><br/></p>
          <p>2.7. Any Participant who is found not to be compliant with the rules of the Competition shall be automatically disqualified. In the event of any dispute regarding the Competition, the decision of the Promoter shall be final and binding.</p>
          <p><br/></p>
          <p>2.8. You may enter the Competition as many times as you like, but you will need to submit a unique design with every entry.</p>
          <p><br/></p>
          <p>2.9. No existing logos or other copyrighted, registered or trademarked images, slogans, or an augmentation of the listed items, will be allowed</p>
          <p>in submissions. Any inclusion of the aforementioned will result in immediate disqualification.</p>
          <p><br/></p>
          <p>2.10. By entering this Competition, Participants have opted in to allow the Promoter and the respective brands of the Promoter to collect, store</p>
          <p>and use (not share) their personal information for communication, statistical purposes and for marketing communications. The Participant shall at all times be entitled to opt-out of such communications.</p>
          <p><br/></p>
          <p>2.11. No responsibility will be accepted by the Promoter, its associated companies (directors, officers and employees) agents and suppliers, for</p>
          <p>any prizes which are lost, delayed, damaged, misdirected or incomplete, or any inability to deliver to the nominated delivery address</p>
          <p>or any other reasons outside of the Promoter&rsquo;s control.</p>
          <p><br/></p>
          <p>2.12. As far as the law allows, all Participants indemnify the Promoter, its associated companies (directors, officers and employees) agents and suppliers, against any / all claims of whatsoever nature for any loss or damages, whether direct, indirect, consequential or otherwise, arising from any cause whatsoever, (including as a result of any act or omission, whether as a result of gross negligence, misrepresentation, misconduct or otherwise on the part of the Promoters and its associated companies, agents and suppliers), connected to or arising from their participation in any way in this Competition.</p>
          <p><br/></p>
          <p>2.13. By taking part in this Competition, Participants hereby warrant that all information they submit to the Promoter is true, current and complete and the Promoter shall not accept any responsibility for any incorrect information provided by Participants.</p>
          <p><br/></p>
          <p>2.14. The Promoter may decline to award a prize (i.e. refuse to issue a prize to the Participant) if there is a reasonable suspicion of any irregularities or fraudulent activities. The decision of the Promoter in this regard shall be final and binding.</p>
          <p><br/></p>
          <p>2.15. As far as the law allows, all warranties and representations in relation to the Competition not set out in these Terms and Conditions (whether</p>
          <p>express, implied or tacit) are hereby excluded.</p>
          <p><br/></p>
          <p>2.16. As far as the law allows, the Promoter shall only be responsible for those costs which these Terms and Conditions expressly say that the Promoter will pay. The Promoter is not responsible for (i) any and all applicable local taxes and fees; and (ii) all other costs incurred by it, or arising directly or indirectly from, the Participant&apos;s participation in the Competition, or from the acceptance, receipt, use or enjoyment of any voucher or other similar reward. Without limiting the rest of this clause, the Participant will be responsible for the cost of submitting his/her entry into the Competition and any data charges that may, for example, apply, as per the tariff rates charged by the Participant&rsquo;s mobile network provider.</p>
          <p><br/></p>
          <p>2.17. The Promoter reserves the right to terminate the Competition immediately and without notice if circumstances beyond its reasonable control prevent the Promoter, its associated companies (directors, officers and employees) agents and suppliers, from continuing with the Competition. This includes but is not limited to any directive from the Department of Energy, or any other competent authority, to cease the Competition. In the event of such termination, as far as the law allows, all Participants acknowledge that they will have no recourse against the Promoter, its associated companies (directors, officers and employees) agents and suppliers, in respect thereof.</p>
          <p><br/></p>
          <p>2.18. As far as the law allows, the Promoter, the BP Group (their directors, officers and employees) agents and suppliers, shall not be responsible for a Participant&apos;s failure to access the Competition for any reason whatsoever including, by way of example only, as a consequence of communications or network failures.</p>
          <p><br/></p>
          <p>2.19. Use of prizes provided by any third party shall be governed by the terms and conditions of any separate agreement with that third party. The</p>
          <p>Promoter and the BP Group assume no liability whatsoever in relation to any third party provided prizes, even if the Promoter and/or the BP Group have been advised of the possibility of such damages or can anticipate such damages. The prizes must be used in accordance with all applicable health and safety standards and may be subject to height and weight restrictions.</p>
          <p><br/></p>
          <p>2.20. These Terms and Conditions shall be governed by the laws of South Africa.</p>
          <p><br/></p>
          <p>2.21. If any provision of these Terms and Conditions is found to be invalid or unenforceable by any court of competent jurisdiction, then that provision shall be severed from these Terms and Conditions and shall not affect the validity or enforceability of any remaining provisions.</p>
          <p><br/></p>
          <p>2.22. The Promoter&apos;s decision is final and binding and no correspondence will be entered into.</p>
          <p><br/></p>
          <p>3. Competition Prizes and Winners</p>
          <p>A first, a second and a third prize winner will be selected from the entries received from Participants that comply with the Terms and Conditions. A panel of judges, selected by the Promoter, will facilitate the selection process and the determination of the winners based on the designs received. The winners will be announced in the month of September 2021 (or on such a later date as may be determined by the Promoter at its discretion).</p>
          <p><br/></p>
          <p>3.1. Participants stand a chance of winning the following prizes:</p>
          <p><br/></p>
          <p>3.1.1. First Prize: R100,000 (one hundred thousand rand) towards tuition fees and/or expenses related to pursing a creative design passion. This prize shall be paid to the winner after the winner has provided the Promoter with a written statement confirming how the winner intends to use the prize money to further the winner&rsquo;s creative design studies or design passion;</p>
          <p><br/></p>
          <p>3.1.2. Second Prize: 1 (one) MacBook Pro with Adobe Creative Cloud software licence for 1 (one) year;</p>
          <p><br/></p>
          <p>3.1.3. Third Prize: 1 (one) MacBook Pro.&nbsp;</p>
          <p><br/></p>
          <p>If you require any help or have any enquiries in respect of the Competition, please</p>
          <p>use the email address: competition@designacup.co.za</p>
        </div>

      </Container>
    </div>
  );

}
