import React, { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import R from 'ramda';

import Navbar from 'react-bulma-companion/lib/Navbar';
import Container from 'react-bulma-companion/lib/Container';
import Image from 'react-bulma-companion/lib/Image';
import Button from 'react-bulma-companion/lib/Button';

import Logo from '../../../assets/images/logo.svg';
import ExitIcon from '../../../assets/images/exiticon.svg';

export default function Navigation({ pathname }) {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));

  const [auth, setAuth] = useState(!R.isEmpty(user));
  const [mobileNav, setMobileNav] = useState(false);

  // const [open, setOpen] = useState(false);
  //
  // useEffect(() => {
  //   setAuth(!R.isEmpty(user));
  // }, [user.username]);
  //
  // const toggleDropdown = () => setOpen(!open);
  //
  // const closeDropdown = () => setOpen(false);
  //
  // const isHome = (pathname.length === 5)
  //   ? pathname === '/home'
  //   : R.slice(0, 6, pathname) === '/home/';
  //
  // const isTodo = (pathname.length === 5)
  //   ? pathname === '/todo'
  //   : R.slice(0, 6, pathname) === '/todo/';
  //
  // const isSettings = (pathname.length === 9)
  //   ? pathname === '/settings'
  //   : R.slice(0, 10, pathname) === '/settings/';

  return (
    <Navbar fixed="top" shadow className={mobileNav ? 'active top' : 'top'}>
      <Container fluid>
        <Navbar.Brand style={{ alignItems: 'center' }}>
          <Navbar.Item
            onClick={() => dispatch(push('/'))}
            aria-label="main navigation"
            link
          >
            <img src={Logo} alt="" />
          </Navbar.Item>
          <Navbar.Burger
            onClick={() => {
              if (mobileNav) {
                setMobileNav(false);
              } else {
                setMobileNav(true);
              }
            }}
            className={mobileNav ? 'is-active' : ''}
            style={{ color: 'white' }}
          />
        </Navbar.Brand>
        <Navbar.Menu className={mobileNav ? 'is-active' : ''}>
          <Navbar.End>
            <Navbar.Item
              active={pathname === '/'}
              onClick={() => {
                dispatch(push('/'));
                setMobileNav(false);
              }}
              link
            >
              HOME
            </Navbar.Item>
            <Navbar.Item
              active={pathname === '/rules'}
              onClick={() => {
                dispatch(push('/rules'));
                setMobileNav(false);
              }}
              link
            >
              COMPETITION RULES
            </Navbar.Item>
            <Navbar.Item
              active={pathname === '/brief'}
              onClick={() => {
                dispatch(push('/brief'));
                setMobileNav(false);
              }}
              link
            >
              DESIGN BRIEF
            </Navbar.Item>
            <Navbar.Item
              active={pathname === '/prizes'}
              onClick={() => {
                dispatch(push('/prizes'));
                setMobileNav(false);
              }}
              link
            >
              PRIZES
            </Navbar.Item>
            <Navbar.Item
              active={pathname === '/how-to-enter'}
              onClick={() => {
                dispatch(push('/how-to-enter'));
                setMobileNav(false);
              }}
              link
            >
              HOW TO ENTER
            </Navbar.Item>
            <Navbar.Item
              active={pathname === '/showcase'}
              onClick={() => {
                dispatch(push('/showcase'));
                setMobileNav(false);
              }}
              link
            >
              DESIGN SHOWCASE
            </Navbar.Item>

            <div className="interaction-wrapper">
              <Button
                onClick={() => {
                  dispatch(push('/enter'));
                  setMobileNav(false);
                }}
                className="cta-btn"
              >
                ENTER NOW
              </Button>
              <img width="20" src={ExitIcon} alt="" />
            </div>
          </Navbar.End>
        </Navbar.Menu>
      </Container>
      <div className="mobile-menu-footer">
        <div>
          <p
            className="py-3"
            onClick={() => {
              dispatch(push('/media'));
              setMobileNav(false);
            }}
          >MEDIA
          </p>
          <p
            className="py-3"
            onClick={() => {
              dispatch(push('/tcs'));
              setMobileNav(false);
            }}
          >
            TERMS &amp; CONDITIONS
          </p>
          <p
            className="py-3"
            onClick={() => {
              dispatch(push('/contact'));
              setMobileNav(false);
            }}
          >
            CONTACT US
          </p>
        </div>
        <small style={{ opacity: '0.2', fontSize: 'small' }}>© All rights reserved. </small>
      </div>
    </Navbar>
  );
}

Navigation.propTypes = {
  pathname: PropTypes.string.isRequired,
};
