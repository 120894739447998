import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';
import ReactNotification from 'react-notifications-component';
import { useDispatch } from 'react-redux';
import R from 'ramda';

import { attemptGetUser } from '_thunks/user';

import WelcomePage from '_pages/WelcomePage';
import LoginPage from '_pages/LoginPage';
import RegisterPage from '_pages/RegisterPage';
import ShowcasePage from '_pages/ShowcasePage';
import RulesPage from '_pages/RulesPage';
import PrizesPage from '_pages/PrizesPage';
import HowToEnterPage from '_pages/HowToEnterPage';
import EnterPage from '_pages/EnterPage';
import MediaPage from '_pages/MediaPage';
import TcsPage from '_pages/TcsPage';
import ContactPage from '_pages/ContactPage';
import BriefPage from '_pages/BriefPage';
import StatusPage from '_pages/StatusPage';
import ThankYouPage from '_pages/ThankYouPage';
import DonePage from '_pages/DonePage';


import Navigation from '_organisms/Navigation';
import Footer from '_organisms/Footer';

export default function Main({ location }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(attemptGetUser())
      .catch(R.identity)
      .then(() => setLoading(false));
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return !loading && (
    // <div className={`${location.pathname.replace(/\//g, "")}-page`}>
    <div>
      <ReactNotification />
      <Navigation pathname={location.pathname} />
      <div className="main">
        <Switch>
          <Route exact path="/" component={WelcomePage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={RegisterPage} />
          <Route path="/showcase" component={ShowcasePage} />
          <Route path="/rules" component={RulesPage} />
          <Route path="/prizes" component={PrizesPage} />
          <Route path="/how-to-enter" component={HowToEnterPage} />
          <Route path="/enter" component={EnterPage} />
          <Route path="/tcs" component={TcsPage} />
          <Route path="/contact" component={MediaPage} />
          <Route path="/media" component={ContactPage} />
          <Route path="/brief" component={BriefPage} />
          <Route path="/status" component={StatusPage} />
          <Route path="/thank-you" component={ThankYouPage} />
          <Route path="/done" component={DonePage} />
        </Switch>
      </div>
      <Footer pathname={location.pathname} />
    </div>
  );
  /**
   * TODO ROUTES
   * HOME [DONE]
   * DESIGN SHOWCASE [ALMOST DONE CHECK LIGHTBOXXESS]
   * COMPETITION RULES
   * PRIZES
   * HOW TO ENTER
   * ENTER NOW
   * MEDIA
   * CONTACT US
   * TERMS & CONDITIONS
   */
}

Main.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};
