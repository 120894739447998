import React  from 'react';
import { Title, Container, Columns, Column } from 'react-bulma-companion/lib';

export default function MediaPage() {
  return (
    <div className="rules-page page" >
      <Container className="py-1">
        <Title className="heading-1">contact
        </Title>
        <Columns style={{margin: '20px 0'}}>
          <Column >
            <strong style={{ color: "#a2132d"}} className="is-size-4">Email </strong><span style={{color: '#a2132d', margin: '0 8px'}}>|</span> <a href="mailto:competition@designacup.co.za" style={{ color: "#a2132d"}} className="is-size-4">competition@designacup.co.za</a>
          </Column>
          <Column>
            <strong style={{ color: "#a2132d"}} className="is-size-4">Contact number </strong><span style={{color: '#a2132d', margin: '0 8px'}}>|</span> <a href="tel:011 568 9330" style={{ color: "#a2132d"}} className="is-size-4">011 568 9330</a>
          </Column>
        </Columns>
      </Container>
    </div>
  );
}
