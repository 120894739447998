import React  from 'react';
import { Title, Container, Columns, Column } from 'react-bulma-companion/lib';

export default function ContactPage() {
  return (
    <div className="rules-page page" >
      <Container className="py-1">
        <Title className="heading-1">
          media
        </Title>
        <Title className="sub is-2">
          Press Releases
        </Title>
        <a download href="https://designacup.co.za/Press-release.zip" style={{ color: '#EBD9BC', background: '#a2132d', borderRadius: '20px', fontWeight:"bold", alignItems:"center", padding: '5px 20px', margin: '10px 0', display: 'inline-block' }}>DOWNLOAD</a>
      </Container>
    </div>
  );
}
