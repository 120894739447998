import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import R from 'ramda';

import { Column, Columns, Container, Title } from 'react-bulma-companion';
import Field from 'react-bulma-companion/lib/Field';
import Control from 'react-bulma-companion/lib/Control';
import Input from 'react-bulma-companion/lib/Input';
import Button from 'react-bulma-companion/lib/Button';
import { validatePassword, validateUsername } from '../../../utils/validation';
import { postCheckUsername } from '../../../api/users';
import { attemptRegister } from '../../../store/thunks/auth';
import useKeyPress from '../../../hooks/useKeyPress';

export default function RegisterPage() {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));

  const [username, setUsername] = useState('');
  const [usernameMessage, setUsernameMessage] = useState('');
  const [password, setPassword] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [usernameAvailable, setUsernameAvailable] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [idnumber, setIdnumber] = useState('');
  const [contact, setContact] = useState('');
  const [institution, setInstitution] = useState('');
  const [success, setSuccess] = useState(false);

  const handleUsernameChange = e => {
    setUsername(e.target.value);
  };
  const handleNameChange = e => {
    setName(e.target.value);
  };
  const handleSurnameChange = e => {
    setSurname(e.target.value);
  };
  const handleIdchange = e => {
    setIdnumber(e.target.value);
  };
  const handleContactChange = e => {
    setContact(e.target.value);
  };
  const handleinstitutionChange = e => {
    setInstitution(e.target.value);
  };
  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  const register = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    dispatch(attemptRegister(data))
      .catch(R.identity);
  };

  useKeyPress('Enter', register);

  return (
    <div className="enter-page is-fullscreen page">
      <Container>
        {success
          ? (
            <div className="has-text-centered py-5">
              <Title className="heading-1">thank you for registering!</Title>
              <Title className="sub is-size-3">
                You have until XX month to submit your design
              </Title>
            </div>
          )
          : (
            <React.Fragment>
              <Title className="heading-1">
                register
              </Title>
              <Title className="sub is-size-3">
                Simply fill the below.
              </Title>
              <form onSubmit={register}>
                <Columns>
                  <Column>
                    <Field>
                      <Control>
                        <Input
                          type="text"
                          placeholder="Your Name"
                          name="first_name"
                          required
                          value={name}
                          onChange={handleNameChange}
                        />
                      </Control>
                    </Field>
                  </Column>
                  <Column>
                    <Field>
                      <Control>
                        <Input
                          type="text"
                          placeholder="Your Surname"
                          name="last_name"
                          required
                          value={surname}
                          onChange={handleSurnameChange}
                        />
                      </Control>
                    </Field>
                  </Column>
                </Columns>
                <Columns>
                  <Column>
                    <Field>
                      <Control>
                        <Input
                          id="Email"
                          placeholder="Your email address"
                          name="username"
                          required
                          type="text"
                          value={username}
                          onChange={handleUsernameChange}
                        />
                      </Control>
                    </Field>
                  </Column>
                  <Column>
                    <Field>
                      <Control>
                        <Input
                          type="text"
                          placeholder="Your contact number"
                          name="contact"
                          id="contact"
                          value={contact}
                          onChange={handleContactChange}
                        />
                      </Control>
                    </Field>
                  </Column>
                </Columns>
                <Columns>
                  <Column>
                    <Field>
                      <Control>
                        <Input
                          type="text"
                          placeholder="ID number"
                          name="ID"
                          id="ID"
                          value={idnumber}
                          onChange={handleIdchange}
                        />
                      </Control>
                    </Field>
                  </Column>
                </Columns>
                <Columns>
                  <Column>
                    <Field>
                      <Control>
                        <Control>
                          <Input
                            id="password"
                            name="password"
                            placeholder="Password"
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                          />
                        </Control>
                      </Control>
                    </Field>
                  </Column>
                </Columns>
                <Columns>
                  <Column>
                    <Columns style={{ justifyContent: 'space-between' }}>
                      <Column style={{ justifyContent: 'center' }} className="is-7 spaced-div">
                        <p>Are you currently a student?</p>
                      </Column>
                      <Column>
                        <div className="select is-rounded is-fullwidth">
                          <select>
                            <option>Select</option>
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                      </Column>
                    </Columns>
                  </Column>
                  <Column>
                    <Field>
                      <Control>
                        <Input
                          type="text"
                          placeholder="If yes, which institution?"
                          name="institution"
                          id="institution"
                          onChange={handleinstitutionChange}
                        />
                      </Control>
                    </Field>
                  </Column>
                </Columns>
                <Columns>
                  <Column className="spaced-div is-5">
                    <label className="checkbox">
                      <input type="checkbox" />
                      I would like to receive all competition updates
                    </label>
                  </Column>
                  <Column className="spaced-div">
                    <p>
                      <span className="submitreg">
                        By submitting you accept our
                      </span>
                      <a href="/tcs" onClick={() => dispatch(push('/tcs'))}>terms & conditions</a>
                    </p>
                    <Button color="primary" type="submit" size="medium" rounded>SUBMIT</Button>
                  </Column>
                </Columns>
              </form>
            </React.Fragment>
          )}

      </Container>
    </div>
  );
}
